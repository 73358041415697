.internal-container {
    min-height: 100vh;
    padding-left: 10vw;
}

.internal-content {
    margin-top: 7.5vh;
    margin-right: 10%;
    text-align: center;
}

.loggedon {
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: scroll;
}

#loggedon-content {
    position: relative;
    width: 100%;
    padding-top: 7.5vh;
    display: block;
}

#tab-container, #announcements {
    margin: 50px 10vw 0 10vw;
}

#tab-container {
    margin-bottom: 50px;
}

.tab-description {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

/* ===================== */
/*        HEADER         */
/* ===================== */
#internal-header {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.toggle-sidebar {
    background-color: transparent;
    color: rgb(194, 191, 191);
    border: none;
    font-size: 20pt;
    top: 0;
    right: 5%;
}

.toggle-sidebar:hover {
    filter: brightness(90%);
    transition: 0.2s;
}

.show-sidebar {
    display: none;
    position: absolute;
    left: 10%;
    text-align: left;
}

.sidebar-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}

.avobooty {
    width: auto;
    margin: 10% 0;
    left: 40%;
    transform: translateX(-50%);
    position: relative;
}

/* ===================== */

/* ===================== */
/*     ANNOUNCEMENTS     */
/* ===================== */
#announcements {
    padding: 50px 0;
    background-color: var(--lmintgreen);
    text-align: left;
    border-radius: 15px;
}

#announcements h2 {
    margin-bottom: 20px;
    padding: 5px 20px 5px 15%;
    width: fit-content;
    background-color: var(--forestgreen);
    color: white;
}

.announcements-text {
    width: 80%;
    margin: 0 10%;
}
/* ===================== */

/* ===================== */
/*         SIDEBAR       */
/* ===================== */

#internal-sidebar {
    background-color: rgb(247, 247, 247);
    border-right: 1px solid rgb(243, 243, 243);
    padding-top: 25px;
    width: 30vw;
    min-width: 300px;
    padding-left: 3vw;
    line-height: 2.2;
}

.sidebar-link-div {
    margin-bottom: 15px;
    text-align: left;
}

#sidebar-links {
    display: flex;
    flex-direction: column;  
}

#sidebar-links a {
    padding: 0 20px;
    color: var(--darkgrey);
}

#sidebar-links a:hover {
    text-decoration: none;
    background-color: white;
    border-right: 7.5px solid var(--forestgreen);
}

.internal-subtitle {
    color: var(--grey);
    text-align: left;
    margin-bottom: 10px;
}

/* ===================== */

@media (max-width: 767px) {
    #internal-header h1 {
        font-size: 40px;
    }

    #internal-header {
        margin-top: 0 !important;
        margin-bottom: 15px;
    } 

    #announcements h2 {
        padding-left: 5%;
    }

    .avobooty {
        width: 20vw;
        height: auto;
    }

    #internal-sidebar {
        width: 100vw;
        padding-left: 10vw;
    }

    .show-sidebar {
        display: flex; 
    }

    #announcements {
        margin: 50px 0vw 0 0vw;
        border-radius: 0;
    }

    #tab-container {
        margin: 50px 2.5vw 0 2.5vw;
    }
}
  