#logo, .not-apply, .apply, .toggleDiv {
  z-index: 2;
}

.toggleDiv {
  display: none;
}

.topnav {
  overflow: hidden;
  background-color: var(--lmintgreen);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  position: relative;
  z-index: 1;
}

#logo {
  block-size: fit-content;
  width: 300px;
  height: auto;
  padding-left: 50px;
}

#logo-square {
  width: 70px;
  margin-left: -25%;
}

/* Style for logo items */

/* Styles for nav-items */
.topnav #nav-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 600px;
  justify-content: space-between;
}

/* Style the topnav links */

.not-apply, .not-apply:hover {
  text-wrap: nowrap;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.84);
  text-decoration: none;
}

/* nav-items hover effects */
.topnav .not-apply:after {
  content: '';
  width: 0;
  height: 2px;
  display: block;
  background: rgba(0, 0, 0, 0.84);
  transition: width .3s ease;
  opacity: 0.75;
}

.topnav .not-apply:hover:after {
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.84);
  opacity: 0.75;
}

/* Button styles */
.button {
  background-color: var(--forestgreen);
  transition: 0.2s;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 15px;

  /* Text */
  color: white;
  text-decoration: none;
}

/* Button hover effects */
.button:hover {
  color: white;
  text-decoration: underline;
  background-color: #408D60;
}

@media screen and (max-width: 900px) {
  .toggleDiv {
    display: block;
    margin-right: 20px;
  }

  .topnav #nav-items {
    display: none;
  }
}
