.project-div {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 15vw;
  
  left: 50%;
  transform: translateX(-50%);
}

#project-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#project-row img {
  width: 20px;
}

/* Client Project Cards */
.project-client-div {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 15vw;
  
  left: 50%;
  transform: translateX(-50%);
}

#project-client-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#project-client-row img {
  width: 20px;
}

.project-client-container {
  position: relative;
  width: 18vw;
  margin-right: 5vw;
  padding-bottom: 10%;
  border-bottom: 10px solid white;
}

.project-client-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 10px #e9e9e9;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 10px solid var(--forestgreen);
}

.project-client-img-container {
  position: relative;
}

.project-client-img, .project-client-img-loader {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
  margin: 20px 0;
}

.project-client-img-loader {
  z-index: 1;
  background-color: white;
}

.project-client-img, .project-client-container {
  border-radius: 7.5px;
}

/* End Client Project CSS */

.project-subtext {
  color: var(--grey);
  overflow-wrap: normal;
  display: inline-block;
  margin: 0;
}

.project-img-container {
  position: relative;
}

.project-img, .project-img-loader {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
  margin: 20px 0;
}

.project-img-loader {
  z-index: 1;
  background-color: white;
}

.project-img, .project-container {
  border-radius: 7.5px;
}

/* ------ UNCLICKED ------ */
.project-container {
  position: relative;
  width: 18vw;
  padding-bottom: 10%;
  border-bottom: 10px solid white;
}

.project-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 10px #e9e9e9;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 10px solid var(--forestgreen);
}

/* ---------------------- */

/* ------ MODAL ------ */
#project-modal {
  background-color: white;
  position: relative;
  padding: 25px;
  border-radius: 1em;
  width: 50vw;
  display: flex;
  flex-direction: row;
  max-height: 75vh;
}

.project-modal-div {
  display: flex;
  flex-direction: column;
}

.div-text {
  width: 75%;
  padding: 5%;
  overflow: scroll;
}

/* ---------------------- */

@media (max-width: 900px) {
  .project-div {
    width: 20vw;
  }

  .project-img, .project-img-loader {
    width: 20vw;
    height: 20vw;
  }

  .project-container {
    width: 25vw;
  }

  /* Client Projects */
  project-client-div {
    width: 20vw;
  }

  .project-client-img, .project-client-img-loader {
    width: 20vw;
    height: 20vw;
  }

  .project-client-container {
    width: 25vw;
    margin-right: 0px;
  }
  /* End Client Projects*/

  #project-modal {
    flex-direction: column;
    width: 75vw;
  }

  .project-modal-div .project-div, .div-text {
    width: 100%;
    
  }

  .project-modal-div .project-div {
    padding: 0 5% 5% 5%;
  }

  .project-modal-div .project-img {
    display: none;
  }

  .div-text {
    padding-top: 0;
  }
}

@media (max-width: 650px) {
  .project-div {
    width: 35vw;
  }

  .project-img, .project-img-loader {
    width: 35vw;
    height: 35vw;
  }

  .project-container {
    width: 40vw;
  }

  /* Client Projects */
  .project-client-div {
    width: 35vw;
  }

  .project-client-img, .project-client-img-loader {
    padding-left:0;
    width: 35vw;
    height: 35vw;
  }

  .project-client-container {
    width: 40vw;
    margin-right: 0px;
  }
  /* End Client Projects*/
}
