.project-body {
    margin-right: 30px;
    margin-left: 30px;
    padding-left: 10vw;
    padding-right: 10vw;
    text-align: center;
}

.no-margin {
    margin-bottom: 2vw;
}

/* ----- PROJECTS INTRO ----- */
#projects-intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

.projects-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projects-type img {
    width: 200px;
    margin-bottom: 10px;
}

.projects-type p {
    width: 90%;
}
/* ------------------------ */

.project-structure {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 700px;
    display:block;
}

.project-timeline {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 800px;
    padding-top: 5vw;
    display:block;
}

/* Little peeps! */
#littlepeep1 {
    height: 170px;
    position: absolute;
    left: 7%;
    top: 100%;
}
#littlepeep2 {
    height: 170px;
    position: absolute;
    right: 12%;
    top: 170%;
}

#littlepeep3 {
    height: 140px;
    position: absolute;
    top: 0;
    right: 0;
}
#littlepeep4 {
    height: 170px;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(-1);
}

.heading-container {
    position: relative;
}

.project-page-div {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 7.5vh;
    margin-top: 2.5vh;
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
}

.button-row h3 {
    width: 175px;
    font-size: 25px;
}

.projects-button {
    border-style: solid;
    border-width: 15px 20px;
    height: 0px;
    width: 0px;
    margin: 0 15px;
    border-color: white var(--forestgreen) white white;
}

.projects-button:hover {
    cursor: pointer;
    border-color: white forestgreen white white;
}

.projects-button-right {
    transform: scaleX(-1);
}

.unselectable {
    border-color: white lightgray white white;
}

.unselectable:hover {
    border-color: white lightgray white white;
    cursor: auto;
}

@media (max-width: 1000px) {
    .project-body {
        margin-right: 5vw;
        margin-left: 5vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    /* Little peeps! */
    #littlepeep1, #littlepeep2, #littlepeep3, #littlepeep4 {
        visibility: hidden;
    }
}


@media (max-width: 767px) {
    #projects-intro {
        flex-direction: column;
    }

    .projects-type {
        margin: 10px;
    }
    
    .project-structure {
        width: 75vw;
        padding-left: 0px;
    }

    .project-timeline {
        width: 75vw;
        padding-left: 0px;
    }

    .button-row h3 {
        font-size: 14pt;
    }
}