/* Portrait */
@media screen and (max-width: 900px) {
    .aboutus-button {
        font-size: 16px;
        padding: 1.5vh 2vw;
        margin: .75vh;
        border-radius: 25px;
    }

    .aboutus-button {
        margin-top: 4vh;
    }

    .content {
        margin-bottom: 6vh;
    }

    .section {
        margin: 7.5vh 0;
    }

    .text-left {
        flex-direction: column;
    }

    .text-right {
        flex-direction: column-reverse;
        justify-content: baseline;
    }

    .text {
        width: 86vw;
        text-align: left;
    }

    .section-img, .carousel, .carousel-cell img {
        width: 50vw;
        height: auto;
        margin-top: 4vh;
    }

    .carousel, .carousel-cell img {
        width: 40vw;
    }

    .big2 {
        left: 50vw;
    }

    .text.projects {
        margin-left: 0;
    }
}

@media screen and (max-width: 600px) {
    .section-img, .carousel, .carousel-cell img {
        width: 65vw;
    }

    .sitting img, .standing3 img {
        display: none;
    }

    .standing img, .standing2 img {
        height: 75px;
    }

    .puzzle img {
        height: 50vh;
    }
}