.contact {
  text-align: center;
  margin-top: 7.5vh;
  position: relative;
}

#underline {
  background-color: var(--yellowish);
  height: 3vh;
  margin-left: 30vw;
  margin-bottom: 2.5vh;
}

#contact-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5% 10%;
}

#contact-form-section {
  width: 60%;
}

#contact-text-section {
  width: 30%;
}

.contact-text {
  margin-bottom: 30%;
}

#contact-text-section h2 {
  background-color: var(--yellowish);
  padding: 2.5%;
}

#contact-puzzle1, #contact-puzzle2 {
  width: 20vw;
  min-width: 150px;
  position: absolute;
  opacity: 0.25;
  z-index: -1;
  object-fit: cover;
}

#contact-puzzle2 {
  top: 25%;
  right: 5%;
}

#contact-puzzle1 {
  top: 95%;
}

/* Top text box for contact page */ 
.contact #topbox {
  background-color: var(--white);
  height: 90vh;
  width: 60vw;
  border: 1px solid var(--darkgrey);
  padding: 3vw;
  margin: 40px auto;
}

/* Bottom text box for contact page */ 
.contact #bottombox {
  background-color: var(--white);
  width: 60vw;
  border: 1px solid var(--darkgrey);
  padding: 3vh; 
  padding-left: 4vh;
  margin: 40px auto;
}

#email:hover {
    text-decoration: none;
}

.contactTitle {
  margin-top: 2vw;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #contact-content {
    flex-direction: column;
  }

  #contact-text-section, #contact-form-section {
    width: 100%;
  }

  #contact-puzzle1 {
    top: 70%;
  }
  
  .contact-text {
    margin: 2.5% 0 10% 0;
  }
}


@media screen and (max-width: 600px) {
  .contact #bottombox, .contact #topbox{
    width: 80vw;
  }
}

@media screen and (max-width: 450px) {
  .contact #topbox {
    height: 120vh;
  }
}
