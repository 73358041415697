.footer-wrapper {
  background-color: var(--almostwhite);
  padding: 75px 100px 55px 100px;
}

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.Footer a {
  color: var(--grey);
}

.Footer img {
  height: 25px;
  margin-right: 30px;
}

#footer-logo {
  block-size: fit-content;
  width: 250px;
  margin-bottom: 25px;
  height: auto;
} 

#footer-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#footer-header b {
  margin-bottom: 50px;
  color: var(--grey);
}

#footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50vw;
}

.footer-links-col {
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  position: relative;
}

/* shows on row view, hides on col view */
.footer-row {
  display: block;
}

/* shows on col view, hides on row view */
.footer-col {
  display: none;
}

/* OCF Hosting Berkeley Student Organization Disclaimer Note */
.footer-note {
  font-size: 12px;
  text-align: center;
  margin-top: 60px;
  color: var(--grey);
  opacity: 0.8;
}

@media only screen and (max-width: 767px) {
  .Footer {
    flex-direction: column;
    padding: 75px 0px;
  }

  .Footer a {
    font-size: 14px;
  }

  #footer-header {
    align-items: center;

  }

  #footer-links {
    width: 80vw;
    margin-bottom: 50px;
  }

  .footer-row {
    display: none;
  }

  .footer-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
  }

  .Footer img {
    margin-right: 0;
  }
}
