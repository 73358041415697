@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;700&display=swap');

/* card */

.cardSheet {
  position: relative;
  padding-bottom: 10%;
  border-bottom: 10px solid white;
  text-align: center;
  border-radius: 10px;
}

.cardSheet:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 10px #e9e9e9;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 10px solid var(--forestgreen);
}

.cardNameContainer {
  display: flex;
  flex-direction: column;
}

.cardImg {
  width: 160px;
  height: 175px;
  object-fit: cover;
  border-radius: 10px;
  margin: 20px;
}

.cardName {
  margin-top: 2.5px;
  overflow-wrap: normal;
}

.cardRole {
  color: var(--gold);
  overflow-wrap: normal;
  width: 160px;
  margin-left: 20px;
}

/* profile popup */
.profile-background {
  max-height: 95vh;
  padding: 50px;
  background-color: white;
  overflow: scroll;
  border-radius: 30px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-name {
  background-color: var(--yellowish);
}

.profile-role {
  color: var(--gold);
}

.profile-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
  margin: 25px;
}

.profile-link-container {
  position: relative;
  width: 200px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.profile-link img {
  width: 30px;
}

.profile-link img:hover {
  filter: brightness(90%);
  transition: 0.2s;
}

.profile-blurb {
  margin-top: 20px;
}

.profile-text {
  text-align: left;
  width: 100%;
}

.profile-category {
  font-weight: bold !important;
}

/* misc */
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

/* Styling for mobile view */
@media only screen and (max-width: 767px) {
  .profile-background {
    width: 80vw;
    max-height: 80vh;
  }

  .cardImg {
    width: 125px;
    height: 140px;
  }

  .cardRole {
    width: 125px;
  }
  
}

@media only screen and (max-width: 400px) {

  .cardRole {
    margin-bottom: 15px;
  }

  .profile-link-container {
    width: 120px;
  }

  .profile-link img {
    width: 20px;
  }
}