
/* Global Variables */

:root {
  --picture-layer-0: 0;
  --picture-layer-1: 1;
  --picture-layer-2: 2;
}

.Members {
  text-align: center;
  margin-bottom: 7.5vh;
}

/* ----- PROJECTS INTRO ----- */
#members-intro {
  width: 100vw;
  position: relative;
  margin-bottom: 22.5vh;
  padding: 0 12.5vw;

}

#members-blurb {
  line-height: 1.75em;
  font-weight: 400;
}

#members-img {
  width: 40%;
  min-width: 200px;
  margin-bottom: 5vh;
}

#members-img-container {
  position: relative;
}

#members-img-person {
  position: absolute;
  width: 10%;
  min-width: 75px;
  left: 20%;
  bottom: 2.5%;
}
/* ------------------------ */

#GreenHand {
  position: absolute;
  right: 3vw;
  height: 20vw;
  z-index: var(--picture-layer-1);
}

#PinkHand {
  position: absolute;
  left: 4vw;
  margin-top: -2vw;
  height: 12vw;
}

#RedHand {
  position: absolute;
  right: 3vw;
  margin-top: -12vw;
  height: 22vw;
  z-index: var(--picture-layer-2);
}

/* Picture Positions, Mobile View*/

@media only screen and (max-width: 767px)  {
  #GreenHand, #PinkHand, #RedHand {
    display: none;
  }

  #members-intro {
    margin-bottom: 5vh;
  }

  #members-img {
    width: 60%;
  }

  #members-img-person {
    left: 0;
  }
}
