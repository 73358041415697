.prompt {
	letter-spacing: .04em;
	font-weight: bold;
	margin-bottom: 8vw;
	margin-top: 5vw;
	background-image: url("../../images/Rectangle39.png");
	font-size: 2.5rem;
	margin: auto;
	margin-top: 4vw;
	margin-bottom: 4vw;
	letter-spacing: .03em;
	text-align: center;
	width: 50%;
}

@media only screen and (max-width: 767px) {
	.prompt {
		width: 75%;
	}
}
