#service-card {
  /* CSS Box Params */
  margin: 10px;

  border-style: solid;
  border-color: #d5eae2;
  border-radius: 8px;
  border-width: 1.5px;
  box-shadow: 0px 0px 2px 2px #d5eae2;

  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;

  width: 35vw;
  height: 10em;
}

#service {
  /* CSS Box */
  margin-bottom: 10px;

  /* Text */
  font-size: 20px;
  color: #25976c;
  font-weight: bold;
  text-align: left;
}

#service-description {
  /* CSS Box */
  margin-bottom: 0px;

  /* Text */
  font-size: 18px;
  color: #202020;
  text-align: left;
  line-height: 1.2;
}

/* For smaller screens */
@media (max-width: 1200px) {
  #service-card {
    height: 12.5em;

    padding: 25px;
  }
}

@media (max-width: 850px) {
  #service-card {
    height: auto;
    width: 70vw;

    padding: 25px;
  }
}
