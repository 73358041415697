/* ============================ */
/* Styles used throughout page */
/* ============================ */
.aboutus-button {
    width: fit-content;
    padding: 1.5vh 1vw;
    text-decoration: none;
    margin: 6vh 0.5vw;
    border-radius: 50px;
    transition: 0.2s;
}

.aboutus-button {
    color: white;
    background: #6FCF97;
}

.aboutus-button:hover {
    background-color: #4DAB74;
    text-decoration: none;
    color: white;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    overflow: hidden;
    margin: 0;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 75vw;
    margin: 8vh;
}

.section-img-container {
    position: relative; 
}

.text-left, .text-right {
    margin-bottom: 12vh;
}

#about {
    z-index: 3;
}

#project {
    z-index: 2;
}

#community {
    z-index: 1;
}

.text {
    position: relative;
    width: 25vw;
    display: flex;
    flex-direction: column;
}

.text p {
    margin-bottom: 4vh;
}

.text.projects {
    margin-left: 8vw;
}

/* ============================ */
/* Images */
/* ============================ */
.section-img {
    width: 30vw;
    min-width: 350px;
    border-radius: 25px;
}

.carousel {
    width: 25vw;
    min-width: 300px;
    border-radius: 25px;
}

.carousel-cell img {
    width: 25vw;
    min-width: 300px;
    border-radius: 25px;
}
/* ============================ */

/* ============================ */
/* Background Puzzle Pieces & People Illustrations */
/* ============================ */

.puzzle {
    position: absolute;
    opacity: 0.6;
    z-index: -5;
}

.puzzle img {
    position: absolute;
    opacity: 0.6;
}

.big img {
    height: 75vh;
    top: -10vh;
    left: -7.5vw;
    transform: rotate(152deg);
}

.big2 img {
    height: 70vh;
    transform: rotate(75deg);
    top: -15vh;
    left: -5vw;
}

.big3 img {
    height: 66vh;
    transform: rotate(200deg);
    top: -15vh;
    left: -7.5vw;
}

.person {
    position: absolute;
    z-index: 1;
}

.sitting {
    position: absolute;
    bottom: -10%;
    left: -15%;
}

.sitting img {
    width: 100px;
    height: auto;
    transform: scaleX(-1);
}

.standing {
    position: absolute;
    bottom: -10%;
    left: 60%;
}

.standing2 {
    position: absolute;
    bottom: -10%;
    left: 15%;
}

.standing3 {
    position: absolute;
    bottom: 0;
    right: -25%;
    transform: scaleX(-1);
}

.standing img, .standing2 img, .standing3 img {
    width: auto;
    height: 125px;
}

/* ============================ */

/* ==== Sponsors and Destinations ==== */
#sponsors, #destinations {
    width: 100vw;
    position: relative;
    padding: 0 12.5vw;
    align-items: center;
}

.destinations_img {
    width:80px;
    margin:20px;
    position:static;
}

.sponsors_img {
    width: 250px;
    margin: 10px;
    position: static;
}

#members-sponsor-container {
    width:70%;
    max-width:825px;
    margin: auto;
    position: relative;

    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
}

#members-destinations-container {
    width:70%;
    max-width:825px;
    margin: auto;
    margin-bottom: 9vh;
    position: relative;

    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
}

@media only screen and (max-width: 800px)  {
    .destinations_img {
        margin:15px;
        width:50px;
    }

    .sponsors_img {
        margin:10px;
        width:125px;
    }
}

@media only screen and (max-width: 500px)  {
    .destinations_img {
        margin:12px;
        width:35px;
    }

    .sponsors_img {
        margin:8px;
        width:100px;
    }
}

/* ============================ */