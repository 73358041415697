/* Each Section (Service, Client, Partner) */
.section-body {
  margin-right: 30px;
  margin-left: 30px;
  text-align: center;
}

.section-description {
  /* CSS Box */
  margin: auto;
  margin-bottom: 30px;

  width: 50vw;

  /* Text */
  font-size: 20px;
}

.service-cards {
  /* CSS Box */

  /* Flex Boxes */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Industry Cards */
.client-cards {
  /* CSS Box */

  /* Flex Boxes */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Past Partners */
#partners-container {
  width: 70%;
  max-width: 1025px;
  margin: auto;
  margin-bottom: 9vh;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.partners_img {
  width: 200px;
  margin: 25px;
  position: static;
}

/* Contact Buton */
#contact-us {
  /* Button position */
  position: fixed;
  left: 20px;
  bottom: 10px;

  /* Button Appearance */
  display: block;
  height: auto;
  width: 120px;
  border-radius: 20px;
  background-color: var(--forestgreen);
  border: none;
  z-index: 99;

  /* Text */
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  text-decoration: none;
}

#contact-us:hover {
  transition: 0.2s;
  filter: brightness(90%);
  text-decoration: underline;
}

/* For smaller screens */
@media (max-width: 767px) {
  #contact-us {
    /* Button location */
    right: 25px;

    /* Button Appearance */
    width: 90px;

    /* Text */
    font-size: 14px;
  }
}

@media only screen and (max-width: 1500px) {
  .partners_img {
    margin: 22px;
    width: 160px;
  }
}

@media only screen and (max-width: 1245px) {
  .partners_img {
    margin: 18px;
    width: 130px;
  }
}

@media only screen and (max-width: 1000px) {
  .partners_img {
    margin: 15px;
    width: 100px;
  }
}

@media only screen and (max-width: 800px) {
  .partners_img {
    margin: 15px;
    width: 80px;
  }
}
