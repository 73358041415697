.cardHolder {
    display: flex;
    width: 80vw;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin:0 auto;
}

@media only screen and (max-width: 767px) {
    .cardHolder {
        width: 85vw;
    }
}

@media only screen and (max-width: 400px) {
    .cardHolder {
        width: 90vw;
    }
}
