.contact {
    background: linear-gradient(180deg, #E1F3D7 0%, rgba(225, 243, 215, 0.00) 100%);
}

.contact {
    margin-top: 0;
    text-align: center;
    padding-top: 7.5vh;
    position: relative;
}

#headline {
    width: 70%;
    margin: 20px 15%;
    color: var(--forestgreen);
}

#application-open-link {
    background-color: var(--forestgreen);
    border-radius: 12px;
    border: none;
    width: 65.5vw;
    height: 3vw, auto;
    padding: 10px;
    color: white;
    margin-left: 0px;
    margin-top: 0px;
    font-size: 25px;
}

#application-closed-link {
    background-color: #d97a77;
    border-radius: 12px;
    border: none;
    width: 65.5vw;
    height: 3vw, auto;
    padding: 10px;
    color: white;
    margin-left: 0px;
    margin-top: 0px;
    font-size: 25px;
    pointer-events: none;
}

#application-open-link:hover {
    filter: brightness(90%);
    transition: 0.2s;
}

#interest-link-4split {
    background-color: var(--forestgreen);
    border-radius: 12px;
    border: none;
    width: 16vw;
    padding: 10px;
    color: white;
    margin-right: 0.5vw;
    margin-top: 0.5vw;
    height: max-content;
}

#interest-link-3split {
    background-color: var(--forestgreen);
    border-radius: 12px;
    border: none;
    width: 21.5vw;
    padding: 10px;
    color: white;
    margin-right: 0.5vw;
    margin-top: 0.5vw;
}

#interest-link-2split {
    background-color: var(--forestgreen);
    border-radius: 12px;
    border: none;
    width: 32.5vw;
    padding: 10px;
    color: white;
    margin-right: 0.5vw;
    margin-top: 0.5vw;
}

#interest-link-1split {
    background-color: var(--forestgreen);
    border-radius: 12px;
    border: none;
    width: 65.5vw;
    padding: 10px;
    color: white;
    margin-right: 0.5vw;
    margin-top: 0.5vw;
}

#interest-link-4split:hover, #interest-link-3split:hover, #interest-link-1split:hover {
    filter: brightness(90%);
    transition: 0.2s;
}

#padding {
    margin:0.5vw;
}


.header {
    display: flex;
    vertical-align: bottom;
    padding-left: 10%;
}

.header-text {
    text-align: left;
}

#header-subtext {
    width: 65.5vw;
}

#header-logo {
    width: 15%;
    height: 15%;
    flex-shrink: 0;
    margin-right: -60px;
}

#header-title {
    color: #000;
    font-family: Karla;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    padding-top: 70px;
}

.unclickable-link, .unclickable-link:hover {
    color: var(--grey);
    pointer-events: none;
}

#recruitment-timeline, #faqs {
    width: 80vw;
    margin-left: 10vw;
    text-align: left;
}

/* ======================== */
/*         TIMELINE         */
/* ======================== */
#recruitment-timeline {
    /* background: linear-gradient(180deg, #F7FCF5 0%, #FFF 100%); */
    /* border-radius: 15px; */
    padding: 50px 0;
}

#timeline-title {
    text-align: left;
    font-size: 20px;
    padding-bottom: 5px;
    border-bottom: 7px solid var(--forestgreen);
    width: 40%;
    padding-right: 20%;
    margin-top: 50px;
    margin-bottom: 0px;
    float: right;
}

.timeline-event {
    margin: 0 0 40px 5%;
    width: 75%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.event-date-container {
    position: absolute;
    text-align: center;
    width: 100px;
    height: 24px;
    transform: translate(-50%, -45px);
}

.event-date {
    color: #0C780A;
    text-align: center;
    /* position: absolute; */
    /* transform: translate(-50%, -40px); */
}

#event-date-design {
    width: 150.628px;
    position: absolute;
    right: -35px;
    bottom: -40px;
}

.event-description {
    border-left: 2px solid var(--forestgreen);
    padding: 1.25% 7.5% 1.25% 60px;
    margin-top: 75px;
}

.event-description h3 {
    color: #0C780A;
}

.event-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-list-item {
    width: 45%;
}


#contact-puzzle1, #contact-puzzle2, #contact-puzzle3, #contact-puzzle4 {
    width: 20vw;
    min-width: 150px;
    position: absolute;
    opacity: 0.25;
    z-index: -1;
    object-fit: cover;
}

#contact-puzzle1 {
    top: 95%;
}

#contact-puzzle2 {
    top: 25%;
    right: 5%;
}

#contact-puzzle3 {
    top: 160%;
    right: 7%;
}

#contact-puzzle4 {
    top: 170%;
}

/* ======================== */

/* ======================== */
/*           FAQS           */
/* ======================== */
#faqs {
    padding: 0px 7.5vw 100px 7.5vw;
    margin-top: 0;
}

.faq {
    margin-bottom: 25px;
}

.faq-question {
    color: var(--forestgreen);
}
/* ======================== */

@media (max-width: 767px) {
    .event-list {
        flex-direction: column;
    }

    .event-list-item {
        width: 100%;
    }

    #timeline-title {
        width: 75%;
    }

    #application-link {
        width: 75vw;
    }

    #recruitment-timeline, #faqs {
        width: 90vw;
        margin-left: 5vw;
    }

    .header-text {
        
    }

    #application-open-link {
        width: 80.5vw;
        font-size: 14px;
    }

    #application-closed-link {
        width: 80.5vw;
        font-size: 14px;
    }
    
    #interest-link-4split, #interest-link-2split {
        margin-top: 1vw;
        width: 40vw;
        font-size: 12px;
    }

    #interest-link-3split {
        margin-top: 1vw;
        margin-right: 1vw;
        width: 26.17vw;
        font-size: 12px;
    }

    #interest-link-1split {
        margin-top: 1vw;
        width: 80.5vw;
        font-size: 12px;
    }

    #header-title {
        font-size: 40px;
    }
}

@media (max-width: 450px) {
    #timeline-title {
        width: 90%;
        
    }
}
