.side-drawer {
    height: 100%;
    background: white;
    /* box-shadow: 1px 0px 7px; */
    position: fixed;
    top: 0;
    right: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    list-style: none;
    margin-top: 10%;
}

.side-drawer a {
    text-decoration: none;
    color: var(--darkgrey);
    font-size: 24px;
}

.side-drawer li {
    margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
    .side-drawer a {
        font-size: 20px;
    }

    .side-drawer li {
        margin-bottom: 24px;
    }
}
