/* 
Design guide link: https://www.notion.so/Website-Design-Guide-8b8c29e207ab476d95b9ea567ba1a0b7
*/

@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

/* ============================ */
/* Styles used throughout page */
/* ============================ */
:root {
  --darkgreen: #3f8f2f;
  --forestgreen: #4DAB74;
  --seagreen: #57BE97;
  --mintgreen: #AFF1B9;
  --yellowish: #F2F3B7;
  --gold: #DB9E56;
  --lmintgreen: #DCF4D4;
  --almostwhite:#D5EAE2;
  --white: #ffff;
  --black: #000000;
  --darkgrey: #202020;
  --grey: #5E5E5E;
}

* {
  box-sizing: border-box;
  font-family: 'Karla';
  font-weight:  bold;
  color: var(--darkgrey);
}

body {
  margin: 0;
}

/* shared classes */
.hidden, .col {
  display: none;
}

button:focus, a:focus {
  outline: none;
}

/* .row shows only on desktop view; .col shows on mobile */
.row {
  display: block;
}

/*font sizes*/
h1, h2, .h2, h3, .h3, b, .b {
  font-weight: bold;
}

/* Title of pages: see WallImage (about us, projects, etc.) */
h1{
  font-size: 60px;
}

/* Headers/Sections: see Header */
h2, .h2 {
  font-size: 36px !important;
  font-weight: bold;
}

/* Emphasized descriptions/smaller titles: see PageDescription */
h3, .h3 {
  font-size: 20px;
  font-weight: bold;
}

.midtext {
  font-size: 18px;
}

/* Longer descriptions */
p, li, em {
  font-size: 16px;
  font-weight: 400;
}

a, a:hover {
  color: #26976c;
  /* a darker seagreen */
}

strong {
  color: rgba(0, 0, 0, 0.84);
}

#scrollTopButton {
  bottom: 20px;
  right: 20px;
  position: fixed;
  opacity: 0;
  transition: opacity 0.5s;

  height: 4vw;
  width: 4vw;
  /* For if the image can't load */
  font-size: 8px;
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 1vw;
}

#scrollTopImg {
  display: block;
  height: 4vw;
  width: 4vw;
}

#scrollTopImg:hover {
  filter: brightness(75%);
  transition: 0.5s;
}

@media (max-width: 767px) {
  h1 {
    font-size: 52px;
  }

  h2, .h2 {
    font-size: 28px !important;
  }

  h3, .h3 {
    font-size: 18px;
  }

  .midtext {
    font-size: 16px;
  }

  p, b, .b, li, em {
    font-size: 15px;
  }

  .col {
    display: block;
  }
  
  .row {
    display: none;
  }

  #scrollTopButton {
    bottom: 20px;
    right: 20px;
    position: fixed;
    opacity: 0;
    transition: opacity 0.5s;

    height: 10vw;
    width: 10vw;
    /* For if the image can't load */
    font-size: 8px;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 1vw;
  }

  #scrollTopImg {
    display: block;
    height: 10vw;
    width: 10vw;
  }

  #scrollTopImg:hover {
    filter: brightness(75%);
    transition: 0.5s;
  }
}